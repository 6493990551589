// eslint-disable-next-line import/prefer-default-export
export const advisoryHero = {
  title: "SpotOn Restaurant Advisory Council",
  subtext: "Top restaurateurs  informing technology",
};
export const advisorySubHero = {
  title: "Tested, approved, and improved by America's best restaurateurs",
  subtext: `SpotOn’s Restaurant Advisory Council is comprised of highly respected restaurant owners, investors, and entrepreneurs. In addition to proudly using SpotOn solutions in their own businesses, council members provide expertise, guidance, and real-time feedback from successful restaurants, enabling SpotOn to continually develop the best point-of-sale technology and software on the market.`,
  list: [],
  cta: {
    title: "Learn more",
    target: "/demo",
  },
};

export const advisoryWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "Get the platinum service you deserve",
  content:
    "Like you, we believe in delivering exactly what our customers are looking for. We assess your unique requirements and deliver a custom-fit solution for your business needs. And we don’t stop there. We’ll teach you how to get the most from your new tools, and then we’ll continue to earn your business every day with local service and 24/7 support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/page-2",
  },
};

export const advisoryLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Adam Snyder",
      company: "Hat Trick Hospitality",
      blockSubTitle:
        "Adam Synder is Managing Partner at Hat Trick Hospitality which has established four popular venues in San Francisco including Redford, The Brixton, Sabrosa, and the group’s latest endeavour, Rambler, in partnership with Viceroy Hotel Group & Hotel Zeppelin. He began his career in the nightlife industry while studying at the University of San Francisco. Working in some of the area’s most popular establishments, Synder developed a deep knowledge of what truly makes venues attractive to the consumer. Over the years, Synder has also owned and managed multiple successful ventures in Las Vegas, Impala and San Francisco. ",
      blockImg: "adam-s.png",
      forceReverse: false,
    },
    {
      blockTitle: "Aimée Diaz",
      company: "Hector’s Restaurants",
      blockSubTitle:
        "Aimée Carolina Pumarejo Diaz is the Co-Founder and Senior Vice President of Hector’s Restaurants, Inc., Modesto Trattoria, LLC., and Salsas on the Square LLC.  Like many small business owners, her roles are as equally diverse as they are significant.  Aimée takes a long-term vision for each location including branding, aesthetic and structural up-fits, human resource-center, and technological upgrades.  Her goal is to harmoniously lead coworkers and guests to a safe, memorable, and pleasurable experience. Aimée’s greatest contribution to the Diaz Restaurant Group is her keen understanding of the strategic intersection between expectation and execution.  She focuses on trends in hospitality – locally and globally - and is on the forefront of creating them as a reality.  Asheville is recognized as one of our nation’s top food destinations. The Diaz’s vision has pioneered a leading role in keeping Asheville's independent, chef-led food scene on the map for decades. Aimée’s presence in the Asheville community is well established.  She is a recognized face in Sunday school and on athletic fields with her four boys.",
      blockImg: "aimee-d.png",
      forceReverse: false,
    },
    {
      blockTitle: "Alison Sullivan",
      company: "Savoie Partners",
      blockSubTitle: `Alison grew up in Minnesota with a home garden and summer canning projects, but her love for food began in earnest at age 11 with a deceivingly light cheese soufflé.  Alison lived and worked (teaching English) in France after college where she dove deep into regional French cuisine.  Upon returning to the U.S. Alison began her career in the front of the house, managing service and beverage programs, in Austin, Manhattan, and Philadelphia, before landing the opening Pastry Chef gig at The Village Pub (1 Michelin Star).  She then worked under Chef Laurent Gras at The Fifth Floor, and Chef Ron Siegal at Masa’s.  Alison returned to Paris to work at Pierre Hermé and Laurent Duchêne before finding a long-term home at Boulette’s Larder.  After nearly 12 years with Boulette’s and a stint with Delfina, she expanded into the Savory kitchen with PRAIRIE restaurant where, collaborating with Chef and Owner Anthony Strong, she worked as Business Manager and, ultimately, Chef de Cuisine.  Alison is now co-chef and owner (with husband, chef Mark Sullivan) of a new destination restaurant in Tahoe City, CA, Savoie, slated to open in the near future.`,
      blockImg: "alison-s.png",
    },
    {
      blockTitle: "Benson Wang",
      company: "Palm House Hospitality",
      blockSubTitle: `Benson was born into a family of third generation restauranteurs and distillers. In his home, the typical salutation is “are you hungry?” Hospitality is in his blood and what he loves. As a child, Benson would sneak into the dining room and be mesmerized by the sights, sounds and unadulterated emotion around each table. He saw firsthand how food fostered community and connection. 

After earning his B.S. in Business Economics from UCLA, Benson worked for Moelis & Company in their investment banking and private equity group. He went on to join TPG Capital’s fundraising group where was responsible for the sales, marketing and business development of the platform.

In 2013, Benson left finance and returned to his roots by partnering with Anderson Pugash to open Palm House. A year later they opened The Dorian and then founded the parent company, PHH. As co-founder, Benson oversees day-to-day operations, recruitment, financial planning and reporting, purchasing, concept development and best business practices to deliver transportive leisure.

Benson is a board member of the Golden Gate Restaurant Association and an SF Travel committee member. In his free time, he loves biking, hiking, lighting up the grill, and spending time outdoors with his wife and two kids
`,
      blockImg: "benson-w.png",
    },
    {
      blockTitle: "Brent Bolthouse",
      company: "The Bungalow Hospitality Group",
      blockSubTitle:
        "Brent Bolthouse is the founder of The Bungalow Hospitality Group, a collective with a deep history in Southern California and a portfolio that currently includes The Bungalow flagship location in Santa Monica and its sister property in Huntington Beach, along with a forthcoming Long Beach location. As an entrepreneur, hospitality maven, and event producer with 30 years of experience, Bolthouse virtually invented today’s nightlife scene with the development and opening of some of the hottest nightclubs, supper clubs, restaurants, lounges and bars across Southern California. In addition to his current work, he continues to support a number of charitable initiatives including the Los Angeles LGBT Center’s An Evening with Women, the Anti Recidivism Coalition, Baby2Baby and more.",
      blockImg: "brent-b.png",
      forceReverse: false,
    },
    {
      blockTitle: "Burt Rapoport",
      company: "Rapoport’s Restaurant Group",
      blockSubTitle:
        "A visionary, third-generation restaurant aficionado, award-winning restaurateur Burt Rapoport has led an impressive legacy in the South Florida restaurant scene for over three decades. Rapoport started his career in New York City, where his family owned and operated a successful kosher dairy restaurant for fifty years. President of Rapoport’s Restaurant Group in Boca Raton, Florida, he is recognized for his dedication to providing high-quality cuisine for a good value, a warm and welcoming ambiance, and truly attentive service at all of his restaurants. Rapoport’s Restaurant Group has received many honors, including first place in the 2020 Sun Sentinel’s Top Workplaces annual employee survey, for all medium-sized businesses that took part in the survey; recognition from the Restaurant Hospitality magazine in 2018 as one of the RH 25: Coolest Multi-Concept Companies; and first place for the 2013 United States Chamber of Commerce’s Small Business Community Excellence Award.   He is a Trustee for the Boca Raton Chamber of Commerce and holds the title of 2017 Power Leader in Hospitality and 2016 South Florida Ultimate CEO by the South Florida Business Journal.",
      blockImg: "burt-r.png",
      forceReverse: false,
    },
    {
      blockTitle: "Chad Viggiano",
      company: "High Tech Burrito",
      blockSubTitle:
        "Chad Viggiano is the president and owner of High Tech Burrito, a successful 35-year-old chain located in the San Francisco Bay Area. Over the past 30 years, he has been involved in the food manufacturing industry supplying national retailers as well as local chain and retail stores with both proprietary as well as branded products. He is married with two children and resides in the Bay Area.",
      blockImg: "chad-v.png",
      forceReverse: false,
    },
    {
      blockTitle: "Chuck Imerson",
      company: "CEO, Asian Box",
      blockSubTitle:
        "Chuck Imerson was appointed President and CEO of Asian Box in October of 2019. Chuck oversees the organizational direction and development of the brand. Chuck is an industry vet with over 25 years’ experience holding many executive operations positions at high-profile organizations such as Vice President of LYFE Kitchen, Senior Director of Operations at Marie Callender’s and owned and operated his own restaurant.",
      blockImg: "chuck-i.png",
      forceReverse: false,
    },
    {
      blockTitle: "Eric Cloutier",
      company: "EMC Group",
      blockSubTitle: `Born and raised in Montreal, Canada, Eric came to America in 1996 after signing a contract with the NHL’s Boston Bruins. After a series of injuries, Eric was sidelined from the NHL and spent four years playing for various minor league teams. Not content with his career in hockey, Eric decided to leave professional hockey and go into the hospitality industry opening several successful establishments ranging from lounges, nightclubs, restaurants, and entertainment venues over the past 20 years.

His most recent success has been the 2015 opening of The Reserve in DTLA, with $4,000,000 gross in 2019 before COVID-19 closed the doors for 13 months. With the reopening in April 2021, Reserve is projected to mark $6,000,000 in gross revenue at a 40% profit margin.
`,
      blockImg: "eric-c.png",
    },
    {
      blockTitle: "Francois Safieddine",
      company: "Lotus Concepts",
      blockSubTitle: `Coming Soon`,
      blockImg: "francois-s.png",
    },
    {
      blockTitle: "Frohman Anderson",
      company: "EverWatch group",
      blockSubTitle: `Frohman is Co-Founder and Co-CEO of the EverWatch group of companies which include wealth management, asset management, direct private investing, and philanthropic operations. Frohman also assists his family’s ESG and impact investment efforts in the food and energy sectors with climate mitigation as a priority. Frohman is a member of CERES, the leader in climate advocacy, and Tiger21, an international investor network. Prior to founding EverWatch in 2005, Frohman was a General Partner of Champlain Capital Partners, L.P., a $140 million buyout fund. Prior to Champlain Capital Partners, Frohman was Vice President of Strategic Planning and Technology at Princess House, Inc., a direct-sales consumer products company, where he developed and implemented growth strategies and industry-leading IT solutions for a 12,000 person sales force. Prior to Princess House, Frohman was President of FCA Enterprises where he consulted several businesses, including raising and investing capital, and sourcing strategic acquisitions. In 1996, Frohman founded Inergy Online, a pioneering Internet services business, which he sold in 1998. From 1992 to 1996, Frohman was General Manager of Heart and Home, a direct-sales company with manufacturing and national retail distribution. From 1984 to 1992, Frohman worked for Cookson PLC, an industrial materials holding company, and was involved with many aspects of the company’s 100+ acquisitions and growth programs. Frohman received a B.A. in Engineering Sciences from Dartmouth College. Frohman has served as a Director of several companies and private schools. He is a director of The Collis Foundation and EverHope Foundation.`,
      blockImg: "frohman-a.png",
    },
    {
      blockTitle: "Hans van der Reijden",
      company: "Ithaka Hospitality Partners, LLC.",
      blockSubTitle: `With more than 25 years of dedicated service as an international hotelier, Hans van der Reijden is recognized as a hospitality expert and a respected leader.

As founder and CEO, Hans leads Ithaka’s development initiatives and spearheads the company’s academic partnership with Auburn University’s Hospitality Management Program. A visionary leader in the world of luxury hospitality, Hans has a record of successfully launching, turning around and expanding organizations.

Hans was a Partner in Capella Hotel Group from its founding in 2003 until 2018. Prior to joining Capella, he served as an Executive in The Ritz-Carlton Hotel Company for 11 years, opening hotels across the globe.

He was named “Hotelier of the Year” in 2007 and “Humanitarian of the Year” in 2019 by the Alabama Hospitality Association, “Tourism Executive of the Year” by the State of Alabama in 2014 and was awarded the Award of Excellence by the Alabama Travel Council in 2014.  Hans is an active member and has served as Chairman of the Board for both the Alabama Hospitality & Restaurant Association as well as the Alabama Travel Council. He also serves on the advisory board for the Alabama Tourism Department, an appointment by Governor Kay Ivey.
`,
      blockImg: "hans-v.png",
    },
    {
      blockTitle: "John Lanni",
      company: "Thunderdome Restaurants",
      blockSubTitle: `Johnathan Lanni grew up in Hamilton, OH just outside of Cincinnati. John had his sights set on the restaurant industry, working in his father’s restaurants from an early age. John attended Badin High School and then went on to study at the School of Hotel Administration at Cornell University. Upon graduating in 2003, he worked for Lettuce Entertain You in Chicago. In 2005 he and his brother Joe decided to start their own restaurant business. After creating Currito in 2005 and SoHi Grilled Sandwiches in 2010, Thunderdome Restaurant Group was officially formed in 2012 with the addition of the Bakersfield. John enjoys tennis, golf, and skiing, as well as spending time with friends and family.`,
      blockImg: "john-l.png",
    },
    {
      blockTitle: "Kamal Azzouz",
      company: "Restaurant owner and Entrepreneur",
      blockSubTitle: `Coming Soon`,
      blockImg: "kamal-a.png",
    },
    {
      blockTitle: "Kevin Youkilis",
      company: "Owner, Loma Brewing Co.",
      blockSubTitle:
        "Former Major League Baseball player that hated the short hops in baseball but loved the hops of craft beer, Kevin Youkilis’ vision to open Loma Brewing Company came from the perks of traveling across the country and exploring breweries while playing baseball. If you ever saw him play, you can understand why he needed a beer after a game to taper the adrenaline. Once he stopped playing baseball professionally, he wanted to find business ventures that consumed his passion to work and to be on a team. He is currently opening up a coffee roasting facility, Loma Coffee Company, in Portland, OR.",
      blockImg: "kevin-y.png",
      forceReverse: false,
    },
    {
      blockTitle: "Matt Blair",
      company: "World Wrapps Restaurants",
      blockSubTitle:
        "An industry veteran, Matthew Blair has spent more than 25 years in the restaurant business. A graduate of the University of California San Diego, Blair is the original Founder of World Wrapps Restaurants, which is credited with starting the Wrapp craze in San Francisco in 1995. He has recently taken back the reins and is preparing the restaurant group for another phase of growth. Blair is part of a number of charitable organizations, and lives in Marin County, California with his wife and two children.",
      blockImg: "matt-b.png",
      forceReverse: false,
    },
    {
      blockTitle: "Matthew Kenney",
      company: "Matthew Kenney Cuisine",
      blockSubTitle:
        "Matthew Kenney is one of the world’s first leading chefs at the forefront of plant based cuisine, an author of 12 cookbooks and a best-selling memoir, a culinary educator, and CEO of Matthew Kenney Cuisine, a multifaceted lifestyle company specializing in plant-based cuisine throughout several unique markets. Kenney graduated from the French Culinary Institute and has earned several awards, including being named one of America’s Best New Chefs by Food and Wine Magazine and was twice nominated as a Rising Star Chef in America by the James Beard Foundation. Currently, Kenney has 28 restaurants operating throughout 15 international cities spanning 5 continents, including PLANT CITY, the world’s first plant-based food hall.",
      blockImg: "matthew-k.png",
      forceReverse: false,
    },
    {
      blockTitle: "Michael Mina",
      company: "MINA Group",
      blockSubTitle:
        "With accolades including James Beard Foundation “Who’s Who of Food & Beverage” inductee, Bon Appétit Chef of the Year and the International Food and Beverage Forum’s Restaurateur of the Year, award-winning chef and restaurateur Michael Mina continues to dazzle the culinary world with bold dining concepts that have contributed to San Francisco’s reputation as a world-class epicurean destination and made him an integral part of the dining scene across the country. His culinary and business vision led to the founding of his company, MINA Group, with partner Andre Agassi in 2002. Under the auspices of MINA Group, he has opened over 45 operations across the United States and Dubai.",
      blockImg: "michael-m.png",
      forceReverse: false,
    },
    {
      blockTitle: "Mourad Belkacem",
      company: "Texican Café",
      blockSubTitle:
        "Mourad is the founder of Texican Café, a restaurant serving up El Paso style, Mexican cuisine for over 33 years. Over the years, Mourad has scaled the award-winning concept to four high-volume location throughout the Greater Austin region. Mourad gained his expertise from the ground up, learning the industry hands-on as a pizza maker, a sous Chef in a Michelin starred Paris institution, and as a franchise consultant to a large pizza chain. He also opened and operated a Sports Gastropub concept and a food court concept under one roof for dine-in and delivery. As CEO of Texican Café, Mourad is a hands-on operator who oversees the day-to-day operations of his four restaurants. Deeply involved in the community, Mourad and Texican Café supports local schools and youth programs. ",
      blockImg: "mourad-b.png",
      forceReverse: false,
    },
    {
      blockTitle: "Ray Villaman",
      company: "Tahoe Restaurant Group",
      blockSubTitle:
        "Ray Villaman is currently the President and owner of Tahoe Restaurant Group, including Base Camp Pizza, which was recently named one of the top 25 pizzerias in the country by USA Today. Ray is a veteran restaurant executive with over 200 restaurant openings with multiple brands, and is a sought after leader in the restaurant industry. In addition to being a board member of the California Restaurant Association, Ray is on the advisory board of four companies focused on the restaurant vertical. \n" +
        "\n" +
        "Ray is a previous board member with United Enterprise Fund (NYC), specializing in restaurant franchise investments, and has assisted with deal flow and due diligence, as well as deal structure/financing for numerous investments. He has spoken to national audiences in major metropolitan areas across the nation, including as the keynote speaker at the Restaurant Leadership Conference in 2011.\n" +
        "\n",
      blockImg: "ray-v.png",
      forceReverse: false,
    },
    {
      blockTitle: "Stryker Scales",
      company: "Blue Barn, Padrecito, Tipsy Pig, Mamanoko",
      blockSubTitle: `Coming Soon`,
      blockImg: "",
    },
    {
      blockTitle: "Tony Greenberg",
      company: "SingleThread, Little Saint",
      blockSubTitle: `Tony Greenberg is the founder of UPVentures LLC, a real estate development firm based in NYC and Brooklyn. Tony partnered with Kyle and Katina to develop SingleThread and manage the large cast of designers, artisans, and unique collaborators who have brought the Connaughtons' vision of SingleThread to life.`,
      blockImg: "tony-g.png",
    },
  ],
};

export const advisoryTrusted = [
  {
    title: "91%",
    subtext:
      "Of Millennials trust online reviews as much as friends and family",
  },
  {
    title: "88%",
    subtext: "Of people search online before visiting a business",
  },
  {
    title: "50%",
    subtext: "Of loyal customers are more likely to try new products",
  },
];

export const advisoryMultiSection = {
  featureBlocks: [
    {
      blockTitle: "Attract customers with a new website",
      blockSubTitle:
        "Don’t spend your time building your own website or hiring an expensive web developer. Our specialists will build a professional website for you at a do-it-yourself price, so you show up higher in online searches and attract more customers.",
      blockList: [
        "Custom website design",
        "Online appointment booking",
        "Web hosting",
        "Custom website name",
        "Self-service dashboard",
      ],
      blockImg: "auto-parts-website.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/page-2",
      },
    },
    {
      blockTitle: "Grow customer loyalty. And revenue",
      blockSubTitle:
        "Increase sales by offering loyalty rewards that encourage your customers to visit more often. SpotOn Loyalty is easy to set up, easy to run, and your customers will love it. Most importantly, it will help you build your customer database so you can stay connected with your customers.",
      blockList: [
        "Create custom rewards",
        "Enroll customers during checkout",
        "Increase sales with each check-in & redemption",
      ],
      blockImg: "loyalty-mobile-laptop.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/page-2",
      },
    },
    {
      blockTitle: "Make payments & invoicing easy",
      blockSubTitle:
        "If you supply parts to local shops, our Virtual Terminal gives you a fast and professional way to invoice your clients, set up recurring billing plans, and let your clients pay by credit card. We can also help you save money on in-store payments.",
      blockList: [
        "Online payments & deposits",
        "In-store payments",
        "Custom invoices & reminders",
        "Recurring payments",
      ],
      blockImg: "payment-hardware.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/page-2",
      },
    },
    {
      blockTitle: "Market like a pro",
      blockSubTitle:
        "We make it easy to connect with your customers, whether it’s to send a limited-time deal,  advertise new products, or announce new hours. With SpotOn Marketing, you’ll be able to send branded emails and deals quickly and easily to every customer who has enrolled in your loyalty program or has their card in file in your Virtual Terminal.",
      blockImg: "marketing-outlined.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/page-2",
      },
    },
    {
      blockTitle: "Manage reviews. Boost your reputation",
      blockSubTitle:
        "Don’t leave your online reputation to chance. Our review management software makes it easy to monitor all your reviews from sites like Yelp, Google, and Facebook in one place. You also get automated alerts when new reviews are posted—so that you can not only monitor your reputation, but also improve it.",
      blockImg: "reviews-phone.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const advisoryCTA = {
  title: "Sign up for a free demo.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/restaurants/demo",
  },
};

export const advisoryVideo = {
  title: "Used by pros",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  videoUrlId: "wx4JxSMsC4s",
};

export const advisoryTestimonials = {
  title: "What our Restaurant Advisors are saying",
  reviews: [
    {
      quote:
        "We need to look at the entire brand as a whole. We would never be able to do this many things if we had to pull data and train on multiple platforms. SpotOn is the only tech partner that provides integration into all the platforms we need.",
      reviewer: "Matthew Kenney",
      rating: null,
      reviewerPhoto: "mk.png",
    },
    {
      quote:
        "When you look at the complexity of the restaurant industry, the pace at which SpotOn is innovating to address some of the industry's biggest challenges is really great.",
      reviewer: "Michael Mina",
      rating: null,
      reviewerPhoto: "mm.png",
    },
    {
      quote:
        "As a Restaurant Advisor, I'm very excited and appreciative to interact with such a great management team and be part of such a dynamic, innovation process at SpotOn.",
      reviewer: "Frohman Anderson",
      rating: null,
      reviewerPhoto: "fa.png",
    },
    {
      quote:
        "What's been so compelling about working with SpotOn is that the customer service and response time is above and beyond. When you need someone, they are there. When you've got a line out the door, and an hour of people waiting online to order, that makes all the difference.",
      reviewer: "Ray Villaman",
      rating: null,
      reviewerPhoto: "rv.png",
    },
    {
      quote:
        "I’m very thankful that I switched over to SpotOn because the online ordering has been great for many reasons, but the customer support aspect of being able to talk to someone when I need it, or having SpotOn’s Chief Product Officer talk to your entire team has just been so helpful. For SpotOn to continue that sort of service is extremely important because when you are running a Saturday night, 3 deep at the bar and the computers go down, you need to be able to call someone. Having that access at SpotOn and the ability to talk to anybody or numerous people at once, is extremely reassuring.",
      reviewer: "Stryker Scales",
      rating: null,
      // reviewerPhoto: "rv.png",
    },
    {
      quote:
        "It’s great to see SpotOn has such a nimble team that’s open to suggestions from restaurateurs to make their products better.",
      reviewer: "Brent Bolthouse",
      rating: null,
      reviewerPhoto: "bb.png",
    },
  ],
};

export const advisoryArticles = {
  title: "Real Talk with Restaurateurs",
  bgText: "Articles.",
};

export const advisoryFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};
