import React, { useEffect, useState } from "react";
import PropTypes, { object } from "prop-types";
import loadable from "@loadable/component";
import { Row, Typography } from "antd";
import dayjs from "dayjs";
import { ghostApi } from "../../api/ghostApi";
import { viewports } from "../../style-vars";

const { Title } = Typography;
const { smOrSmaller, mdOrSmaller } = viewports;

const Articles = (props) => {
  const { sectionData, blogFilters, style, className } = props;
  const { title, bgText } = sectionData;
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    ghostApi.posts
      .browse(blogFilters)
      .then((posts) => {
        setBlog(posts);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <section className={`articles ${className}`} style={style}>
      {sectionData && (
        <>
          <p className="articles__bg-text">{bgText}</p>
          <Title level={2} className="text-center">
            {title}
            <span className="blue_dot">.</span>{" "}
          </Title>
        </>
      )}
      <Row gutter={[32, 64]}>
        {blog.map((entry, idx) => {
          const ArticleCard = loadable(() => import("./ArticleCard"));
          return (
            <ArticleCard
              title={entry.title}
              headerImg={entry.feature_image}
              sizing={entry.sizing}
              category={entry?.primary_tag?.name ?? ""}
              snippet={entry.custom_excerpt}
              publishedDate={dayjs(entry.published_at).format("MMMM-DD-YYYY")}
              linkTo={entry.url}
              key={`blog-entry__${idx.toString()}`}
            />
          );
        })}
      </Row>

      <style jsx>{`
        .articles {
          position: relative;
          padding: 0 160px 0px 160px;
        }

        .articles > :global(h2) {
          font-weight: 800 !important;
          margin-bottom: 48px !important;
          padding-top: 80px;
          position: relative;
        }

        .articles__bg-text {
          position: absolute;
          color: #f3f4f6;
          z-index: 0;
          font-weight: 900;
          top: -3%;
          left: 50%;
          font-size: calc((75vw - 8rem) / 3.55);
          transform: translateY(-30%) translateX(-50%);
        }

        @media ${mdOrSmaller} {
          .articles {
            padding: 10px;
            max-width: 95%;
            margin: auto;
            margin-bottom: 40px;
          }

          .articles__bg-text {
            display: none;
          }
        }

        @media ${smOrSmaller} {
          .articles {
            margin-bottom: 40px !important;
          }

          .articles > :global(h2) {
            paddding-top: 0;
          }
        }
      `}</style>
    </section>
  );
};

Articles.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  blogFilters: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  style: object,
  className: PropTypes.string,
};
Articles.defaultProps = {
  sectionData: {},
  blogFilters: "",
  style: {},
  className: "",
};

export default Articles;
