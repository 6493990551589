import React from "react";
import loadable from "@loadable/component";
import { Accordion } from "@mantine/core";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import {
  advisoryHero,
  advisorySubHero,
  advisoryLargeFeatureA,
  advisoryCTA,
  advisoryTestimonials,
  advisoryArticles,
} from "../../data/advisory-data";
import { heroSlides, heroSuccess } from "../../data/success-stories-data";
// import { tempData } from '../../data/article-data';
// import NoVisualsCustom from '../../components/NoVisuals/NoVisualsCustom';
// import LargeFeatures from '../../components/LargeFeatures/LargeFeatures';
// import LargeCta from '../../components/CTAs/LargeCta';
// import SuccessStories from '../../components/SuccessStories/SuccessStories';
// import TestmonialReviews from '../../components/TestimonialReviews/TestmonialReviews';
// import PartnerLogos from '../../components/PartnerLogos/PartnerLogos';
// import Articles from '../../components/Articles/Articles';

import ogImage from "../../images/global_assets/og-image.png";

import Section from "../../components-v2/Base/Section";
import Image from "../../components/ImageQuerys/HeroImages";
import ImageLF from "../../components/ImageQuerys/LargeFeaturesImages";
import RandomImage from "../../components/ImageQuerys/RandomImages";
import Heading from "../../components-v2/Base/HeadingBuilder";
import CtaPrimary from "../../components-v2/Base/CtaPrimary";
import { restaurantArticles } from "../../data/restaurant-data";
import Articles from "../../components/Articles/Articles";

const NoVisualsCustom = loadable(() =>
  import("../../components/NoVisuals/NoVisualsCustom")
);
const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);
const LargeCta = loadable(() => import("../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../components/SuccessStories/SuccessStories")
);
const TestmonialReviews = loadable(() =>
  import("../../components/TestimonialReviews/TestmonialReviews")
);
const PartnerLogos = loadable(() =>
  import("../../components/PartnerLogos/PartnerLogos")
);
const TopRestaurantsLogos = loadable(() =>
  import("../../components-v2/TopRestaurantsLogos")
);

function Icon() {
  return (
    <svg
      className="plus-icon"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="vertical-line"
        d="M15 1V29"
        stroke="#090D12"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="horizontal-line"
        d="M1 15H29"
        stroke="#090D12"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const AdvisoryBoard = () => {
  return (
    <Layout>
      <SEO
        title="Restaurant Software Company Advisory Business Board | SpotOn"
        description="SpotOn has an experienced food & restaurant advisory board group. With the mix of software and food experience let SpotOn take your business to the next level."
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/company/advisory-board/"
      />
      {/* <BHero sectionData={advisoryHero} heroBg={heroBg} /> */}

      <Section className="mt-32 relative">
        <div className="relative overflow-hidden rounded-[24px]">
          <Image
            imageName="advisory-hero.png"
            className="w-full min-h-[550px] md:min-h-0 advisory-hero-image"
          />
          <div
            className={`
          absolute flex flex-col justify-center items-center
          left-0 top-0 right-0 bottom-0 m-auto
        `}
          >
            <Heading
              level={1}
              injectionType={2}
              withBlueSymbol
              className="text-white text-center"
            >
              {advisoryHero.title}
            </Heading>
            <p className="text-white text-center text-[20px]">
              {advisoryHero.subtext}
            </p>
          </div>
        </div>
      </Section>

      <Section className="mt-16 ">
        <div className="bg-black-800 rounded-[24px] py-12 px-4 md:px-10 max-w-[1440px] md:mx-[30px]">
          <Heading
            level={3}
            injectionType={3}
            withBlueSymbol
            className="mx-auto max-w-[540px] text-center"
          >
            {advisorySubHero.title}
          </Heading>

          <p className="text-black-200 text-p max-w-[540px] text-center w-full mx-auto">
            {advisorySubHero.subtext}
          </p>

          <CtaPrimary
            title={advisorySubHero.cta.title}
            target={advisorySubHero.cta.target}
            className="mx-auto mt-8"
          />
        </div>
      </Section>

      <Section className="accordion-section">
        <div className="xl:px-20">
          <Accordion
            className="accordion-1 mt-16 2xl:mt-20"
            chevronPosition="right"
            disableChevronRotation
            // defaultValue="Michael Mina"
            multiple
          >
            {advisoryLargeFeatureA.featureBlocks.map((item) => (
              <Accordion.Item key={item.blockTitle} value={item.blockTitle}>
                <Accordion.Control chevron={Icon()} className="py-[30px]">
                  <div className="">
                    <Heading
                      level={3}
                      injectionType={3}
                      withBlueSymbol
                      className=""
                      noMargin
                    >
                      {item.blockTitle}
                    </Heading>
                    <div className="text-black-200 text-p -mt-4">
                      {item.company}
                    </div>
                  </div>
                </Accordion.Control>

                <Accordion.Panel className="text-black-200 text-p pb-4">
                  <div className="flex flex-col lg:flex-row gap-8 items-center md:items-start">
                    <ImageLF
                      imageName={item.blockImg}
                      className="md:max-w-[230px] min-w-[230px]"
                    />
                    <p className="text-p text-black-200">
                      {item.blockSubTitle}
                    </p>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Section>

      <LargeCta sectionData={advisoryCTA} />

      <TestmonialReviews
        sectionData={advisoryTestimonials}
        className="mt-10 lg:mt-32"
        // heightMode="first"
      />

      {/* <section className="max-w-[1150px] mx-auto my-10 xl:my-20 px-4 md:px-10">
        <RandomImage
          imageName="advisory-board-logos-mobile.png"
          className="lg:hidden"
        />
        <RandomImage
          imageName="advisory-board-logos-desktop.png"
          className="hidden lg:block"
        />
      </section> */}

      <TopRestaurantsLogos />

      <Articles
        className="md:mt-10 lg:mt-[150px] xl:mt-[240px]"
        sectionData={advisoryArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:real-talk-with-restaurateurs",
        }}
        cornerStone={false}
      />

      <style jsx="true" global>{`
        .advisory-hero-image.gatsby-image-wrapper,
        .advisory-hero-image.gatsby-image-wrapper img {
          border-radius: 24px !important;
        }

        .accordion-1 button.mantine-Accordion-control,
        .accordion-1 .mantine-Accordion-content {
          padding-left: 0;
        }
        .accordion-1 button.mantine-Accordion-control:hover {
          background: none !important;
        }
        .accordion-1 svg path {
          transition: all 800ms;
          transform: rotate(0);
          transform-origin: 50% 50%;
        }

        .accordion-1
          .mantine-Accordion-item[data-active="true"]
          svg
          path.vertical-line {
          //transform: rotate(270deg);
          transform: rotate(450deg);
        }
      `}</style>
    </Layout>
  );
};

export default AdvisoryBoard;
